import React from "react";
import Button from "../Button";
import WomanPullUp from "../images/WomanPullUp";
import "../../styles/LandingPageHeader.css";
import { navigate } from "gatsby";
import ReactGA from "react-ga";

function handleClick() {
  ReactGA.event({
    category: "Landing Page",
    action: "click Sign up in header",
    label: "user has clicked Sign up in header"
  });

  return navigate("/login");
}

function LandingPageHeader() {
  return (
    <div className="LandingPageHeader">
      <h1>Cali Skills</h1>
      <div style={{ zIndex: 1 }}>
        <div
          style={{ margin: "32px auto", maxWidth: "1000px", padding: "0 8px" }}
        >
          <div className="LandingPageHeader__bottom-section">
            <h1 className="LandingPageHeader--hollow">Drop the weights.</h1>
            <h1 className="LandingPageHeader--solid">Pick up the rings.</h1>
            <p>
              Learn calisthenics, with the Cali Skills web app. With
              calisthenics your body is the gym. Progress across a range of
              skills, from the Plank, to the Pull Up, to the Human Flag, all
              while growing stronger and healthier. You'll also learn some cool
              party tricks on the way.
            </p>
          </div>
        </div>
        <div style={{ height: "72px" }}>
          <Button handleClick={handleClick} size="large">
            Sign up
          </Button>
        </div>
      </div>
      <div className="LandingPageHeader__image-container">
        <WomanPullUp alt="Woman performing a Pull Up" />
      </div>
    </div>
  );
}

export default LandingPageHeader;
