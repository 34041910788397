import React, { useState, useEffect } from "react";
import Icon from "../Icon";
import classnames from "classnames";
import "../../styles/LandingPageSection.css";
import { useWindowSize } from "react-use";

interface ImageStyles {
  transform: string;
  maxWidth?: string;
  width?: string;
  height?: string;
}

interface Props {
  title: string;
  icon: string;
  iconTitle: string;
  subtitle: string;
  bodyText: string;
  additionalContent?: React.ReactNode;
  image: React.ReactNode;
  direction?: "reverse" | "forward";
  imageStyles: [ImageStyles, ImageStyles];
}

function LandingPageSection(props: Props) {
  const { width } = useWindowSize();
  const [isMobile, setMobileState] = useState(false);

  const mobileStyles = {
    margin: "0 auto",
    ...props.imageStyles[0]
  };

  const desktopStyles = {
    position: "absolute" as "absolute",
    ...props.imageStyles[1]
  };

  useEffect(() => {
    if (width < 750) {
      return setMobileState(true);
    }

    return setMobileState(false);
  }, [width]);

  return (
    <section
      className={classnames("LandingPageSection", {
        "LandingPageSection--reverse": props.direction === "reverse"
      })}
    >
      <br />
      <div className="LandingPageSection__text-content">
        <div className="LandingPageSection__text-content__title">
          <Icon title={props.iconTitle} containerWidth={64} src={props.icon} />
          <h2>{props.title}</h2>
        </div>
        <h3 className="LandingPageSection__subtitle">{props.subtitle}</h3>
        <p className="LandingPageSection__body-text">{props.bodyText}</p>
        {props.additionalContent}
      </div>
      <div
        data-testid={isMobile ? "mobile-image" : "desktop-image"}
        className="LandingPageSection__image"
      >
        <div style={isMobile ? mobileStyles : desktopStyles}>{props.image}</div>
      </div>
    </section>
  );
}

export default LandingPageSection;
