import React from "react";
import Button from "../Button";
import { Link, navigate } from "gatsby";
import "../../styles/LandingPageFooter.css";
import ReactGA from "react-ga";

function signUp() {
  ReactGA.event({
    category: "Landing Page",
    action: "click Sign up in footer",
    label: "user has clicked Sign up in footer"
  });

  return navigate("/login");
}

function goToApp() {
  ReactGA.event({
    category: "Landing Page",
    action: "click 'get started' in footer",
    label: "user has clicked 'get started' in footer"
  });

  return navigate("/home");
}

function LandingPageFooter() {
  return (
    <footer className="LandingPageFooter">
      <h1>Take your first step into the world of calisthenics.</h1>
      <div>
        <Button handleClick={goToApp} size="large">
          Get started
        </Button>
        <span style={{ width: "24px", display: "inline-block" }} />
        <Button handleClick={signUp} size="large">
          Sign up
        </Button>
      </div>
      <Link style={{ marginTop: "48px" }} to="/privacy-policy/">
        Privacy Policy
      </Link>
      <Link style={{ marginTop: "24px" }} to="/terms-and-conditions/">
        Terms and Conditions
      </Link>
    </footer>
  );
}

export default LandingPageFooter;
