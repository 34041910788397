import React, { useEffect } from "react";
import SEO from "../components/SEO";
import LandingPageHeader from "../components/landingPage/LandingPageHeader";
import { TrophyIcon, RulerIcon, ComputerIcon, PlanningIcon } from "../icons";
import FakeSkill from "../components/FakeSkill";
import MacBookSkillTree from "../components/images/MacBookSkillTree";
import PixelTooltip from "../components/images/PixelTooltip";
import TabletExercisePage from "../components/images/TabletExercisePage";
import IPhoneWorkout from "../components/images/IPhoneWorkout";
import LandingPageSection from "../components/landingPage/LandingPageSection";
import LandingPageFooter from "../components/landingPage/LandingPageFooter";
import "../styles/LandingPage.css";
import useAuth from "../hooks/useAuth";
import { navigate } from "gatsby";
import NavRoutes from "../components/NavRoutes";

function LandingPage() {
  const { getAuthenticationState, tokenRenewalComplete } = useAuth();

  useEffect(() => {
    if (getAuthenticationState()) {
      navigate("/callback/");
    }
  }, [tokenRenewalComplete, getAuthenticationState]);

  return (
    <div className="LandingPage">
      <NavRoutes />
      <SEO path="/" />
      <LandingPageHeader />
      <div className="LandingPage__why-cali-skills">
        <section>
          <h3>We don't make calisthenics complicated.</h3>
          <p>
            Let us take the hard work out of fitness tracking and workout
            planning, so you can focus on achieving your goals. With
            Cali Skills you can:
          </p>
          <ul
            style={{
              listStyle: "circle",
              margin: "0 0 0 16px",
              color: "#16181c"
            }}
          >
            <li>
              <p>Track the skills you've achieved</p>
            </li>
            <li>
              <p>Measure your progress</p>
            </li>
            <li>
              <p>Learn the exercises</p>
            </li>
            <li>
              <p>Perform bespoke workouts</p>
            </li>
          </ul>
          <p>
            There's nothing to download. Cali Skills works directly in
            your device's browser.
          </p>
        </section>
      </div>
      <div style={{ margin: "0 auto", maxWidth: "1000px", padding: "0 8px" }}>
        <LandingPageSection
          title="Achieve"
          icon={TrophyIcon}
          iconTitle="Trophy Icon"
          subtitle="Keep track of where you are today, and where you're going tomorrow."
          bodyText="Track the skills you've achieved through our progression roadmaps. Achieving a skill is satisfying, both in real-life and in-app."
          additionalContent={<FakeSkill text="Click me" />}
          image={<MacBookSkillTree alt="macbook displaying skill tree" />}
          imageStyles={[
            { transform: "translate(0, 0)", maxWidth: "800px" },
            { transform: "translate(-8%, -45%)", width: "800px" }
          ]}
        />
        <LandingPageSection
          direction="reverse"
          title="Measure"
          icon={RulerIcon}
          iconTitle="Ruler Icon"
          subtitle="We're here with you, every rep of the way."
          bodyText="Measure your progress at the rep level so you'll always be making positive steps toward your goals. And after? Look back on your journey with progress dashboards as beautiful as your Handstand."
          image={<PixelTooltip alt="Pixel phone displaying tooltip" />}
          imageStyles={[
            { transform: "translate(0, 0)", maxWidth: "500px" },
            { transform: "translate(-20%, -45%)", width: "400px" }
          ]}
        />
        <LandingPageSection
          title="Perform"
          icon={PlanningIcon}
          iconTitle="Planning Icon"
          subtitle="Spend less time planning, and more time performing."
          bodyText="Our Workout Builder lets you easily create workouts based on your current progress. Complete the workout in-app, and we'll automagically update your progress. It all comes full circle."
          image={<IPhoneWorkout alt="IPhone displaying workout" />}
          imageStyles={[
            { transform: "translate(0, 0)", maxWidth: "350px" },
            { transform: "translate(30%, -45%)", width: "330px" }
          ]}
        />
        <LandingPageSection
          direction="reverse"
          title="Learn"
          icon={ComputerIcon}
          iconTitle="Computer Icon"
          subtitle="Turn knowledge into power, and power into strength."
          bodyText="The Cali Skills exercise library contains all the information you need to excel in your bodyweight fitness journey. You’ll find everything you need to learn each exercise."
          image={
            <TabletExercisePage alt="Apple Tablet displaying exercise page" />
          }
          imageStyles={[
            { transform: "translate(0, 0)", maxWidth: "500px" },
            { transform: "translate(-20%, -45%)", width: "500px" }
          ]}
        />
      </div>
      <LandingPageFooter />
    </div>
  );
}

export default LandingPage;
