import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";

interface Props {
  alt: string;
}

interface StaticQueryData {
  file: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
}

function PixelTooltip({ alt }: Props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "pixel-tooltip.png" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      `}
      render={(data: StaticQueryData) => {
        return (
          <Img
            imgStyle={{ width: "100%" }}
            fluid={data.file.childImageSharp.fluid}
            alt={alt}
          />
        );
      }}
    />
  );
}

export default PixelTooltip;
