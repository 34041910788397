import React, { useState } from "react";
import classNames from "classnames";
import "../styles/FakeSkill.css";
import { Link } from "gatsby";
import Tippy from "@tippy.js/react";

interface Props {
  text: string;
}

function FakeSkill(props: Props) {
  const [isSelected, setSelectedState] = useState(false);

  return (
    <Tippy
      interactive
      visible={isSelected}
      delay={200}
      content={
        <p style={{ margin: 0 }}>
          Satisfying right? It feels even better clicking a skill that you've
          achieved in real life. <Link to="/login">Sign up</Link> to track your
          achieved skills.
        </p>
      }
    >
      <div
        tabIndex={0}
        style={{ position: "relative", margin: "0 auto", width: "fit-content" }}
      >
        <span
          className={classNames("FakeSkill__overlay", {
            "FakeSkill__overlay--selected": isSelected
          })}
        />
        <div
          onClick={() => setSelectedState(!isSelected)}
          data-testid="fake-skill"
          className={classNames("FakeSkill__container", {
            "FakeSkill__container--selected": isSelected,
            "FakeSkill__container--unlocked": !isSelected
          })}
        >
          <div className="FakeSkill">
            <p>{props.text}</p>
          </div>
        </div>
      </div>
    </Tippy>
  );
}

export default FakeSkill;
